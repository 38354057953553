import React from 'react';

import {
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_PRESCRIPTION,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {
    submitProblemQuestion,
    undoProblemQuestion,
} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import ChoiceQuestion from 'questions/components/ChoiceQuestion.js';
import {types} from 'questions/helpers.js';

/*
    Clinical:
    N/A

    Technical:
    The ProblemQuestionStep is shown whenever a Symptom, Condition
    or Medication has specific questions linked to it.

    Dependencies:
    One is generated per question on Symptom, Condition or Medication
    when a problem is selected in the ProblemSearchStep

    Future:
    N/A
*/

export default createStep({
    question: ChoiceQuestion,
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
    }),
    reloadAction: reloadProblemQuestionStep,
    submitAction: submitProblemQuestionStep,
    undoAction: undoProblemQuestionStep,
});

export function submitProblemQuestionStep({answer, answers, props}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const {qid, questionType} = props;
            let toAdd = [];
            switch (questionType) {
                case types.RADIO:
                    if (answer.id) toAdd = [answer.id];
                    break;
                case types.CHECKBOX:
                    toAdd = answers.map((ans) => ans.id);
                    break;
                case types.GROUPING:
                    toAdd = answers;
                    break;
            }
            await dispatch(submitProblemQuestion(qid, props.problem, toAdd));
        }
    };
}

export function reloadProblemQuestionStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const problemQuestionSteps = store.form.steps.filter(
            (step) => step.component.type === 'ProblemQuestionStep',
        );
        const currentStepIndex = problemQuestionSteps.findIndex(
            (step) => step.id === stepId,
        );
        if (currentStepIndex === -1) {
            return;
        }
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'ProblemQuestionStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {
                qid,
                problem,
                questionType,
                answers,
            } = previousStep.component.props;

            let questionResults;
            switch (problem.type) {
                case PROBLEM_TYPE_SYMPTOM:
                    questionResults =
                        store.data.consultations.consultationSymptoms[
                            problem.consultationSymptomId
                        ].questionResults;
                    break;
                case PROBLEM_TYPE_CONDITION:
                    questionResults =
                        store.data.consultations.pastConditions[
                            problem.pastConditionId
                        ].questionResults;
                    break;
                case PROBLEM_TYPE_PRESCRIPTION:
                    questionResults =
                        store.data.consultations.medicationCourses[
                            problem.medicationCourseId
                        ].questionResults;
                    break;
            }

            if (!questionResults[qid]) {
                return;
            }

            let selectedAnswers;
            if (questionType === types.GROUPING) {
                selectedAnswers = questionResults[qid];
            } else {
                selectedAnswers = answers.filter((answer) =>
                    questionResults[qid].includes(answer.id),
                );
            }

            switch (questionType) {
                case types.GROUPING:
                    return {answers: selectedAnswers};
                case types.RADIO:
                    return {answer: selectedAnswers[0] || {id: null}};
                case types.CHECKBOX:
                    return {answers: selectedAnswers};
                default:
                    return;
            }
        }
    };
}

export function undoProblemQuestionStep({props}) {
    return async function(dispatch) {
        const qid = props.qid;
        await dispatch(undoProblemQuestion(qid, props.problem));
    };
}

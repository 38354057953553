import {defineMessages} from 'react-intl';

import actions from 'better_consult/actions/types.js';
import {updateMedicationCourse} from 'consultations/actions/consultationActions.js';
import {
    addStepsForNewPrescription,
    addStepsForRepeatPrescription,
    setDataOnStep,
} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

export const messages = defineMessages({
    noAnswer: {
        id: 'consultations.RepeatPrescriptionStep.noAnswer',
        defaultMessage: 'noAnswer',
    },
    questionTitle: {
        id: 'consultations.RepeatPrescriptionStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    yesAnswer: {
        id: 'consultations.RepeatPrescriptionStep.yesAnswer',
        defaultMessage: 'yesAnswer',
    },
    notSure: {
        id: 'consultations.RepeatPrescriptionStep.notSure',
        defaultMessage: 'notSure',
    },
});

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        answers: [
            {
                id: 'yes',
                text: props.intl.formatMessage(messages.yesAnswer),
            },
            {
                id: 'no',
                text: props.intl.formatMessage(messages.noAnswer),
            },
            {
                id: 'notSure',
                text: props.intl.formatMessage(messages.notSure),
            },
        ],
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadRepeatPrescriptionStep,
    submitAction: submitRepeatPrescriptionStep,
    undoAction: undoRepeatPrescriptionStep,
});

export function reloadRepeatPrescriptionStep(stepId) {
    return function(dispatch, getState) {
        const {form} = getState();
        const currentSteps = form.steps.filter(
            (step) => step.component.type === 'RepeatPrescriptionStep',
        );
        const currentStepIndex = currentSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = form.previousSteps.filter(
            (step) => step.component.type === 'RepeatPrescriptionStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {answer, problem} = previousStep.component.props;
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'problem',
                    value: problem,
                }),
            );
            return {
                answer,
            };
        }
    };
}

export function submitRepeatPrescriptionStep({answer, props, stepId}, reload) {
    return async function(dispatch) {
        if (answer) {
            const {problem} = props;
            if (answer.id === 'no' || answer.id === 'notSure') {
                if (!reload) {
                    await dispatch(
                        updateMedicationCourse(problem.medicationCourseId, {
                            field: 'currentlyTaking',
                            toReplace: false,
                        }),
                    );
                }
                dispatch(addStepsForNewPrescription({stepId, problem}));
            } else {
                dispatch(addStepsForRepeatPrescription({stepId, problem}));
            }
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.answer',
                    value: answer,
                },
            });
        }
    };
}

export function undoRepeatPrescriptionStep({stepId, answer, props}) {
    return async function(dispatch, getState) {
        if (answer) {
            const {steps} = getState().form;
            const {medicationCourseId} = props.problem;
            const medicationForProblemsStep = steps.find(
                (step) =>
                    step.component.type === 'MedicationForProblemsStep' &&
                    step.component.props.medicationCourseId ===
                        medicationCourseId,
            );
            if (!(answer.id === 'yes')) {
                await dispatch(
                    updateMedicationCourse(medicationCourseId, {
                        field: 'currentlyTaking',
                        toReplace: true,
                    }),
                );
                dispatch(
                    setDataOnStep({
                        stepId: medicationForProblemsStep.id,
                        field: 'questionType',
                        value: null,
                    }),
                );
                dispatch(
                    setDataOnStep({
                        stepId: medicationForProblemsStep.id,
                        field: 'hasNoAnswerOption',
                        value: false,
                    }),
                );
            }
            dispatch(
                setDataOnStep({
                    stepId: medicationForProblemsStep.id,
                    field: 'isRepeatPrescription',
                    value: null,
                }),
            );
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.answer',
                    value: null,
                },
            });
        }
    };
}

import {CLINICAL_REVIEW_OF_SYSTEMS_API} from 'apiEndpoints.js';
import actions from 'better_consult/actions/types.js';
import {addStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import http from 'http.js';
import CheckpointQuestion from 'questions/components/CheckpointQuestion.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

/*
    Clinical:
    N/A

    Technical:
    This step will call review of systems API and generate
    review of systems steps with symptoms and conditions
    based on the body systems returned. Only gets added if
    Feature Flag US_REVIEWS_OF_SYSTEMS is enabled.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckpointQuestion,
    submitAction: submitReviewOfSystemsCheckpointStep,
    reloadAction: reloadReviewOfSystemsCheckpointStep,
});

export function reloadReviewOfSystemsCheckpointStep(stepId) {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'ReviewOfSystemsCheckpointStep',
        );
        if (!previousStep) {
            return;
        }
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props',
                value: {
                    bodySystems: previousStep.component.props.bodySystems,
                },
            },
        });
        return {};
    };
}

export function submitReviewOfSystemsCheckpointStep({stepId}, reload) {
    return async function(dispatch, getState) {
        let bodySystems;
        if (reload) {
            const step = getState().form.steps.find(
                (step) => step.id === stepId,
            );
            bodySystems = step.component.props.bodySystems;
        } else {
            const {id} = getState().data.consultations.consultation;
            bodySystems = await http.get({
                url: `${CLINICAL_REVIEW_OF_SYSTEMS_API}${id}/`,
            });
            bodySystems = bodySystems.map(snakeToCamelObjectKeys);
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props',
                    value: {
                        bodySystems,
                    },
                },
            });
        }
        bodySystems.forEach((bodySystem) => {
            const symptoms = bodySystem.reviewOfSystemsSymptoms.map(
                (symptom) => ({
                    id: symptom.id,
                    text: symptom.name,
                    type: 'symptom',
                }),
            );
            const conditions = bodySystem.reviewOfSystemsConditions.map(
                (condition) => ({
                    id: condition.id,
                    text: condition.name,
                    type: 'condition',
                }),
            );
            const answers = symptoms.concat(conditions);
            if (answers.length) {
                dispatch(
                    addStep({
                        type: 'ReviewOfSystemsStep',
                        props: {
                            name: bodySystem.name,
                            bodySystemId: bodySystem.id,
                            answers,
                        },
                        parentId: stepId,
                    }),
                );
            }
        });
    };
}

import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {addStep} from 'consultations/actions/formActions.js';
import {
    createStep,
    findConditionRelatedSymptom,
    findPreviousProblemStep,
} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    rangeMinimumText: {
        id: 'consultations.SeverityStep.rangeMinimumText',
        defaultMessage: 'rangeMinimumText',
    },
    title: {
        id: 'consultations.SeverityStep.title',
        defaultMessage: 'title',
    },
});

/*
    Clinical:
    On a scale of 0-10, how painful is the symptom ON AVERAGE.

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    props: {
        rangeType: true,
    },
    mapState: (store, props) => ({
        rangeMinimumText: props.intl.formatMessage(messages.rangeMinimumText),
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
    }),
    reloadAction: reloadSeverityStep,
    submitAction: submitSeverityStep,
    undoAction: undoSeverityStep,
});

export function reloadSeverityStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'SeverityStep',
            stepId,
        });
        if (previousStep) {
            const {problem} = previousStep.component.props;
            const severity =
                store.data.consultations.consultationSymptoms[
                    problem.consultationSymptomId
                ].severity;
            return {
                answer: {
                    id: severity,
                },
            };
        }
    };
}

export function submitSeverityStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        const store = getState();
        const {consultationSymptoms} = store.data.consultations;
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        const {consultationSymptomId} = problem;
        const isConditionRelatedSymptom = findConditionRelatedSymptom(
            consultationSymptoms,
            consultationSymptomId,
        );
        if (!reload) {
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'severity',
                    toReplace: answer.id,
                }),
            );
        }
        if (!isConditionRelatedSymptom && answer.id > 0 && answer.id < 10) {
            dispatch(
                addStep({
                    type: 'SeverityAtWorstStep',
                    props: {
                        problem,
                        severity: answer.id,
                    },
                    parentId: stepId,
                }),
            );
        }
    };
}

export function undoSeverityStep({stepId}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {consultationSymptomId} = step.component.props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'severity',
                toReplace: null,
            }),
        );
    };
}

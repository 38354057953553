import {defineMessages} from 'react-intl';

import {
    removeRelatedSymptomV2,
    submitRelatedSymptomV2,
    updateConsultation,
} from 'consultations/actions/consultationActions.js';
import {
    addSteps,
    addStepsForSymptom,
    constitutionalSymptomsAsked,
    createConstitutionalSteps,
    setDataOnStep,
    stepsForConditionRelatedSymptom,
} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import ScaleQuestion, {
    YES,
    NO,
    NOT_SURE,
} from 'questions/components/ScaleQuestion.js';
import {limitAnswers} from 'questions/components/Question.js';

export const messages = defineMessages({
    constitutionalTitle: {
        id: 'consultations.RelatedSymptomsStep.constitutionalTitle',
        defaultMessage: 'constitutionalTitle',
    },
    title: {
        id: 'consultations.RelatedSymptomsStep.title',
        defaultMessage: 'title',
    },
    otherTitle: {
        id: 'consultations.RelatedSymptomsStep.otherTitle',
        defaultMessage: 'otherTitle',
    },
    diagnosedConditionTitle: {
        id: 'consultations.RelatedSymptomsStep.diagnosedConditionTitle',
        defaultMessage: 'diagnosedConditionTitle',
    },
    undiagnosedConditionTitle: {
        id: 'consultations.RelatedSymptomsStep.undiagnosedConditionTitle',
        defaultMessage: 'undiagnosedConditionTitle',
    },
});

/*
    Clinical:
    The backbone of differential diagnosis. Knowing as many symptoms as
    possible makes the doctor's job easier. The idea behind asking about
    specific symptoms is to make patients think about things that they may not
    think are related (this includes what are called 'signs' which are
    effectively symptoms that a patient will rarely self-diagnose, such as
    jaundice).

    Technical:
    The RelatedSymptomsStep is the core of BC. After the patient selects their
    first;

    a) Symptom:
    We collect all conditions that can cause this symptom and then ask about
    all the other symptoms that can be caused by those conditions.

    b) Condition:
    We ask about all the symptoms that the condition can cause

    RelatedSymptomsSteps are broken up into each BodySystem. Any BodySystems
    with less than 3 symptoms will be asked in one final step.

    Dependencies:
    N/A

    Future:
    - Ron has big plans for weighting the symptoms based on several factors
    (age, sex, country of origin). Ideally this means we ask about the more
    likely symptoms first.
*/

export default createStep({
    question: ScaleQuestion,
    mapState: (store, props) => {
        let title;
        if (props.other) {
            title = props.intl.formatMessage(messages.otherTitle);
        } else if (props.constitutional) {
            title = props.intl.formatMessage(messages.constitutionalTitle);
        } else if (props.problem.type === 'condition') {
            let message;
            if (props.problem.diagnosed) {
                message = messages.diagnosedConditionTitle;
            } else {
                message = messages.undiagnosedConditionTitle;
            }
            title = props.intl.formatMessage(message, {
                condition: props.problem.displayText,
            });
        } else {
            title = props.intl.formatMessage(messages.title, {
                bodySystem: props.bodySystem,
            });
        }
        return {
            subTitle: props.problem.displayText,
            title,
        };
    },
    reloadAction: reloadRelatedSymptomsStep,
    submitAction: submitRelatedSymptomsStep,
    undoAction: undoRelatedSymptomsStep,
});

export function reloadRelatedSymptomsStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const currentStepIndex = store.form.steps
            .filter((step) => step.component.type === 'RelatedSymptomsStepV2')
            .findIndex((step) => step.id === stepId);
        if (currentStepIndex === -1) {
            return;
        }
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'RelatedSymptomsStepV2',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {
                answers,
                yesAnswers,
                noAnswers,
            } = previousStep.component.props;

            // The original list of answers are not limited
            const limitedAnswers = limitAnswers(answers);

            // add back `answer` value to each answer
            const selectedAnswers = limitedAnswers.map((answer) => {
                if (yesAnswers.includes(answer.id)) {
                    return {value: YES, ...answer};
                } else if (noAnswers.includes(answer.id)) {
                    return {value: NO, ...answer};
                } else {
                    return {value: NOT_SURE, ...answer};
                }
            });

            // override filtered answers set in addStepsForSymptom
            // with the original answers shown at time of submission
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'answers',
                    value: limitedAnswers,
                }),
            );

            const data = {answers: selectedAnswers};
            // required for radio question
            if (selectedAnswers.length === 1) {
                data.answer = selectedAnswers[0];
            } else {
                data.answer = {id: null};
            }
            return data;
        }
    };
}

export function submitRelatedSymptomsStep(
    {stepId, props, answer, answers},
    reload,
) {
    return async function (dispatch, getState) {
        const {constitutional, problem} = props;
        const yesAnswers = [];
        const noAnswers = [];
        const constitutionalSymptomsSteps = [];
        const symptomsSteps = [];
        const relatedSymptomIds = [];
        const relatedProblems = [];
        let askConstitutional = false;
        let store = getState();

        if (answer && answer.id !== null) {
            answers = [answer];
        }
        if (answers === undefined) {
            answers = [];
        }

        for (const answer of answers) {
            if (answer.value === YES) {
                yesAnswers.push(answer.id);
            } else if (answer.value === NO) {
                noAnswers.push(answer.id);
            }
            const params = {
                constitutional,
                problem,
                relatedSymptomCertainty: answer.value,
                symptomId: answer.id,
            };
            let data;
            if (reload) {
                const {relatedSymptoms} = store.data.consultations;
                data = Object.values(relatedSymptoms).find(
                    (rs) => rs.symptomId === answer.id,
                );
            } else {
                data = await dispatch(submitRelatedSymptomV2(params));
            }
            if (problem.type === 'condition' && answer.value === YES) {
                const relatedProblem = {
                    displayText: answer.text,
                    consultationSymptomId: data.id,
                    symptomId: answer.id,
                    type: 'symptom',
                };
                store = getState();
                if (problem.diagnosed) {
                    relatedProblems.push(relatedProblem);
                    // eslint-disable-next-line max-len
                    const clinicalSymptom =
                        store.data.clinical.symptoms[answer.id];
                    askConstitutional = !!clinicalSymptom.askConstitutional;
                } else {
                    dispatch(
                        addStepsForSymptom({
                            stepId,
                            problem: relatedProblem,
                        }),
                    );
                }
            }
            relatedSymptomIds.push(data.id);
        }

        if (askConstitutional) {
            store = getState();
            if (!constitutionalSymptomsAsked(store)) {
                constitutionalSymptomsSteps.push(
                    ...createConstitutionalSteps(store, problem),
                );
                if (constitutionalSymptomsSteps.length) {
                    dispatch(
                        addSteps({
                            steps: constitutionalSymptomsSteps,
                            parentId: stepId,
                        }),
                    );
                }
            }
        }

        if (relatedProblems.length) {
            relatedProblems.forEach((relatedProblem) => {
                symptomsSteps.push(
                    ...stepsForConditionRelatedSymptom(store, relatedProblem),
                );
            });
        }
        if (symptomsSteps.length) {
            dispatch(
                addSteps({
                    steps: symptomsSteps,
                    parentId: stepId,
                    insertAfterFunc: insertAfterFunc(stepId),
                }),
            );
        }
        if (!reload) {
            await dispatch(
                updateConsultation({
                    field: 'relatedSymptoms',
                    toAdd: yesAnswers,
                }),
            );
        }
        dispatch(
            setDataOnStep({
                stepId,
                field: 'relatedSymptomIds',
                value: relatedSymptomIds,
            }),
        );
        // Keep a record of each answer value for reload. We can infer
        // not-sure answers if they are not in yes/no answers
        dispatch(
            setDataOnStep({
                stepId,
                field: 'yesAnswers',
                value: yesAnswers,
            }),
        );
        dispatch(
            setDataOnStep({
                stepId,
                field: 'noAnswers',
                value: noAnswers,
            }),
        );
    };
}

export function undoRelatedSymptomsStep({props, answer, answers}) {
    return async function (dispatch) {
        const {problem, relatedSymptomIds} = props;

        let toRemove = [];
        if (answer && answer.value === YES) {
            toRemove = [answer.id];
        } else if (answers) {
            answers.forEach((answer) => {
                if (answer.value === YES) {
                    toRemove.push(answer.id);
                }
            });
        }
        await dispatch(
            updateConsultation({
                field: 'relatedSymptoms',
                toRemove,
            }),
        );
        for (const csid of relatedSymptomIds) {
            await dispatch(
                removeRelatedSymptomV2(csid, problem.consultationSymptomId),
            );
        }
    };
}

export function insertAfterFunc(stepId) {
    return function (store) {
        const relatedSymptomsStepIndex = store.form.steps.findIndex(
            (step) => step.id === stepId,
        );
        const repeatStepIndex = store.form.steps
            .slice(relatedSymptomsStepIndex + 1)
            .findIndex((step) => step.component.type === 'RepeatStep');
        return relatedSymptomsStepIndex + repeatStepIndex;
    };
}

import {defineMessages} from 'react-intl';

import {
    patternAnswers,
    PATTERN_CONSTANT_CONTINUOUS,
    PATTERN_COMES_GOES,
    PATTERN_ONE_OFF,
    translateAnswers,
} from 'clinical/helpers.js';
import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {addSteps} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    title: {
        id: 'consultations.PatternStep.title',
        defaultMessage: 'title',
    },
});

/*
    Clinical:
    Determines the pattern of the symptom, which is used to add various steps

    Three possibilities:
    - Constant and continuous
    - Comes and goes / occurred a few times
    - Occurred once or twice

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        answers: translateAnswers(patternAnswers, props.intl),
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
    }),
    reloadAction: reloadPatternStep,
    submitAction: submitPatternStep,
    undoAction: undoPatternStep,
});

export function stepsForPattern({
    answer,
    problem,
    isExistingPatient,
    isNewProblem,
}) {
    const patternSteps = [];
    if (!isExistingPatient || isNewProblem) {
        patternSteps.push({
            type: 'FirstOccurrenceStep',
            props: {
                problem,
            },
        });
    }
    switch (answer.id) {
        case PATTERN_CONSTANT_CONTINUOUS.id: {
            patternSteps.push({
                type: 'TimeOfDayStep',
                props: {problem},
            });
            break;
        }
        case PATTERN_COMES_GOES.id: {
            patternSteps.push(
                {
                    type: 'FrequencyStep',
                    props: {problem},
                },
                {
                    type: 'EpisodeLengthStep',
                    props: {
                        problem,
                        isOneOff: false,
                    },
                },
            );
            if (!isExistingPatient) {
                patternSteps.push({
                    type: 'RecentChangeStep',
                    props: {
                        problem,
                        isExistingPatient: false,
                    },
                });
            }
            break;
        }
        case PATTERN_ONE_OFF.id: {
            patternSteps.push({
                type: 'EpisodeLengthStep',
                props: {
                    problem,
                    isOneOff: true,
                },
            });
            break;
        }
    }
    return patternSteps;
}

export function reloadPatternStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const currentSteps = store.form.steps.filter(
            (step) => step.component.type === 'PatternStep',
        );
        const currentStepIndex = currentSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'PatternStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const currentStep = currentSteps[currentStepIndex];
        const {problem} = currentStep.component.props;
        const {consultationSymptomId} = problem;
        if (
            consultationSymptomId !==
            previousStep.component.props.problem.consultationSymptomId
        ) {
            return;
        }
        // eslint-disable-next-line max-len
        const consultationSymptom =
            store.data.consultations.consultationSymptoms[
                consultationSymptomId
            ];
        if (!consultationSymptom) {
            return;
        }
        return {
            answer: {id: consultationSymptom.pattern},
        };
    };
}

export function submitPatternStep({stepId, props, answer}, reload) {
    return async function(dispatch, getState) {
        const store = getState();
        const {isExistingPatient} = store.data.consultations.consultation;
        const {problem, isNewProblem} = props;
        if (!reload) {
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'pattern',
                    toReplace: answer.id,
                }),
            );
        }

        const steps = stepsForPattern({
            answer,
            problem,
            isExistingPatient,
            isNewProblem,
        });
        if (steps.length) {
            dispatch(
                addSteps({
                    steps,
                    parentId: stepId,
                }),
            );
        }
    };
}

export function undoPatternStep({props}) {
    return async function(dispatch) {
        const {consultationSymptomId} = props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'pattern',
                toReplace: null,
            }),
        );
    };
}

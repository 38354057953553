import {addStep, setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import CheckpointQuestion from 'questions/components/CheckpointQuestion.js';
import deduplicate from 'utils/deduplicate.js';

/*
    Clinical:
    N/A

    Technical:
    This step adds a ProblemPastConditionStep with the choices being
    conditions associated with the presenting problem(s). If there are
    no associated conditions it adds a RepeatStep with PastConditionStep
    as the step type instead.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckpointQuestion,
    reloadAction: reloadProblemPastConditionsCheckpointStep,
    submitAction: submitProblemPastConditionsCheckpointStep,
});

export function reloadProblemPastConditionsCheckpointStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) =>
                step.component.type === 'ProblemPastConditionsCheckpointStep',
        );
        if (previousStep) {
            const {answers, secondary} = previousStep.component.props;
            return {answers, secondary};
        }
    };
}

export function submitProblemPastConditionsCheckpointStep(
    {stepId, answers, secondary},
    reload,
) {
    return function(dispatch, getState) {
        const {
            consultation,
            consultationSymptoms,
            pastConditions,
        } = getState().data.consultations;
        const {
            symptoms,
            conditions: clinicalConditions,
        } = getState().data.clinical;
        // conditions selected from previous steps, they can be from
        // ProblemSearchStep or MedicationForProblemStep
        const selectedConditions = consultation.pastConditions.map((id) => ({
            id: pastConditions[id].conditionId,
        }));
        const presentingConditions = consultation.pastConditions
            .filter((id) => pastConditions[id].isPresentingProblem)
            .map((id) => clinicalConditions[pastConditions[id].conditionId]);
        const conditions = [];
        // conditions associated with presenting symptoms
        for (const csid of consultation.consultationSymptoms) {
            const cSymptom = consultationSymptoms[csid];
            if (cSymptom.symptomId) {
                const symptom = symptoms[cSymptom.symptomId];
                if (symptom.conditions) {
                    conditions.push(...symptom.conditions);
                }
                if (symptom.pastConditions) {
                    conditions.push(...symptom.pastConditions);
                }
            }
        }
        // conditions associated with presenting conditions
        for (const condition of presentingConditions) {
            conditions.push(...condition.parents);
            conditions.push(...condition.children);
        }
        if (!reload) {
            answers = deduplicate(conditions, (item) => item.id)
                .filter(
                    (item) =>
                        !selectedConditions.some(
                            (selected) => selected.id === item.id,
                        ),
                )
                .map((item) => ({id: item.id, text: item.name}));
            secondary = !!consultation.pastConditions.length;
        }
        if (answers.length) {
            dispatch(
                addStep({
                    type: 'ProblemPastConditionsStep',
                    props: {
                        answers,
                    },
                    parentId: stepId,
                }),
            );
        } else {
            dispatch(
                addStep({
                    type: 'RepeatStep',
                    props: {
                        titleKey: secondary
                            ? 'pastConditionStepSecondaryTitle'
                            : 'pastConditionStepTitle',
                        step: {
                            type: 'PastConditionStep',
                            props: {
                                secondary,
                            },
                        },
                    },
                    parentId: stepId,
                }),
            );
        }
        dispatch(
            setDataOnStep({
                stepId,
                field: 'answers',
                value: answers,
            }),
        );
        dispatch(
            setDataOnStep({
                stepId,
                field: 'secondary',
                value: secondary,
            }),
        );
    };
}

import {defineMessages} from 'react-intl';

import {updatePatient} from 'accounts/actions/accountsActions.js';
import {setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import FormQuestion from 'questions/components/FormQuestion.js';

export const messages = defineMessages({
    firstName: {
        id: 'consultations.PatientNameStep.firstName',
        defaultMessage: 'firstName',
    },
    lastName: {
        id: 'consultations.PatientNameStep.lastName',
        defaultMessage: 'lastName',
    },
    questionTitle: {
        id: 'consultations.PatientNameStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    firstNameInitial: {
        id: 'consultations.PatientNameStep.firstNameInitial',
        defaultMessage: 'firstNameInitial',
    },
    firstNameInitialPlaceholder: {
        id: 'consultations.PatientNameStep.firstNameInitialPlaceholder',
        defaultMessage: 'firstNameInitialPlaceholder',
    },
    lastNameInitial: {
        id: 'consultations.PatientNameStep.lastNameInitial',
        defaultMessage: 'lastNameInitial',
    },
    lastNameInitialPlaceholder: {
        id: 'consultations.PatientNameStep.lastNameInitialPlaceholder',
        defaultMessage: 'lastNameInitialPlaceholder',
    },
    initialsQuestionTitle: {
        id: 'consultations.PatientNameStep.initialsQuestionTitle',
        defaultMessage: 'initialsQuestionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    Is pre-filled the first time the patient fills out BC, we get the first
    name and last name from the PMS before we send the invitation.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: FormQuestion,
    mapState: (store, props) => {
        let inputs;
        let maxLength;
        let title;
        let answers;
        if (props.mustComplyWithHipaa) {
            inputs = [
                {
                    label: props.intl.formatMessage(messages.firstNameInitial),
                    placeholder: props.intl.formatMessage(
                        messages.firstNameInitialPlaceholder,
                    ),
                },
                {
                    label: props.intl.formatMessage(messages.lastNameInitial),
                    placeholder: props.intl.formatMessage(
                        messages.lastNameInitialPlaceholder,
                    ),
                },
            ];
            maxLength = 1;
            title = props.intl.formatMessage(messages.initialsQuestionTitle);
            answers = ['', ''];
        } else {
            inputs = [
                {label: props.intl.formatMessage(messages.firstName)},
                {label: props.intl.formatMessage(messages.lastName)},
            ];
            maxLength = null;
            title = props.intl.formatMessage(messages.questionTitle);
            answers = [props.firstName, props.lastName];
        }
        return {
            answers,
            inputs,
            maxLength,
            title,
        };
    },
    reloadAction: reloadPatientNameStep,
    submitAction: submitPatientNameStep,
    undoAction: undoPatientNameStep,
});

export function reloadPatientNameStep(stepId) {
    return function (dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'PatientNameStep',
        );

        if (previousStep) {
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'originalName',
                    value: previousStep.component.props.originalName,
                }),
            );

            const {firstName, lastName} = getState().data.accounts.patient;
            return {
                answers: [firstName, lastName],
            };
        }
    };
}

export function submitPatientNameStep({stepId, props, answers}, reload) {
    return async function (dispatch) {
        if (!reload) {
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'originalName',
                    value: {
                        firstName: props.firstName || null,
                        lastName: props.lastName || null,
                    },
                }),
            );
            await dispatch(
                updatePatient({
                    field: 'firstName',
                    toReplace: answers[0],
                }),
            );
            await dispatch(
                updatePatient({
                    field: 'lastName',
                    toReplace: answers[1],
                }),
            );
        }
    };
}

export function undoPatientNameStep({props}) {
    return async function (dispatch) {
        await dispatch(
            updatePatient({
                field: 'firstName',
                toReplace: props.originalName.firstName,
            }),
        );
        await dispatch(
            updatePatient({
                field: 'lastName',
                toReplace: props.originalName.lastName,
            }),
        );
    };
}

import {defineMessages} from 'react-intl';

import {
    createPastConditions,
    deletePastConditions,
} from 'consultations/actions/consultationActions.js';
import {addStep, setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    helperTitle: {
        id: 'consultations.ProblemConditionsStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    helperLabel: {
        id: 'consultations.ProblemConditionsStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperContent: {
        id: 'consultations.ProblemConditionsStep.helperContent',
        defaultMessage: 'helperContent',
    },
    questionTitle: {
        id: 'consultations.ProblemConditionsStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    Has the patient ever been diagnosed with conditions in the past that are
    related to any presenting symptoms?

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    props: {
        capitalisation: 'title',
    },
    mapState: (store, props) => ({
        helper: {
            title: props.intl.formatMessage(messages.helperTitle),
            label: props.intl.formatMessage(messages.helperLabel),
            content: props.intl.formatMessage(messages.helperContent),
        },
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadProblemPastConditionsStep,
    submitAction: submitProblemPastConditionsStep,
    undoAction: undoProblemPastConditionsStep,
});

export function reloadProblemPastConditionsStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const problemPastConditionsSteps = store.form.steps.filter(
            (step) => step.component.type === 'ProblemPastConditionsStep',
        );
        const currentStepIndex = problemPastConditionsSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'ProblemPastConditionsStep',
        );
        const previousStep = previousSteps[currentStepIndex];

        if (previousStep) {
            const {answers, pastConditions} = previousStep.component.props;
            if (pastConditions) {
                const conditionIds = pastConditions.map(
                    (pc) =>
                        store.data.consultations.pastConditions[pc]
                            .conditionId,
                );
                const selectedAnswers = answers.filter((answer) =>
                    conditionIds.some((pc) => answer.id === pc),
                );
                return {
                    answers: selectedAnswers,
                    pastConditions,
                };
            }
        }
    };
}

export function submitProblemPastConditionsStep(
    {stepId, answers, pastConditions},
    reload,
) {
    return async function (dispatch) {
        let response = [];
        pastConditions = pastConditions || [];
        if (answers.length && !reload) {
            response = await dispatch(
                createPastConditions(answers.map((item) => ({id: item.id}))),
            );
        }
        dispatch(
            setDataOnStep({
                stepId,
                field: 'pastConditions',
                value: response.length
                    ? response.map((item) => item.id)
                    : pastConditions,
            }),
        );

        dispatch(
            addStep({
                type: 'RepeatStep',
                props: {
                    titleKey: 'pastConditionStepSecondaryTitle',
                    step: {
                        type: 'PastConditionStep',
                        props: {
                            secondary: true,
                        },
                    },
                },
                parentId: stepId,
            }),
        );
    };
}

export function undoProblemPastConditionsStep({stepId}) {
    return async function (dispatch, getState) {
        const step = getState().form.steps.find((step) => step.id === stepId);
        const {pastConditions} = step.component.props;
        if (pastConditions.length) {
            await dispatch(deletePastConditions(pastConditions));
        }
        dispatch(
            setDataOnStep({
                stepId,
                field: 'pastConditions',
                value: undefined,
            }),
        );
    };
}

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

/*
    Clinical:
    N/A

    Technical:
    Only asked when the Symptom has severity_question

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
    }),
    reloadAction: reloadSeverityQuestionStep,
    submitAction: submitSeverityQuestionStep,
    undoAction: undoSeverityQuestionStep,
});

export function reloadSeverityQuestionStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'SeverityQuestionStep',
            stepId,
        });
        if (previousStep) {
            const {
                consultationSymptomId,
            } = previousStep.component.props.problem;
            const {
                severityAnswer,
            } = store.data.consultations.consultationSymptoms[
                consultationSymptomId
            ];
            return {answer: severityAnswer};
        }
    };
}

export function submitSeverityQuestionStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {consultationSymptomId} = step.component.props.problem;
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'severityAnswer',
                    toReplace: answer.id,
                }),
            );
        }
    };
}

export function undoSeverityQuestionStep({stepId}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {consultationSymptomId} = step.component.props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'severityAnswer',
                toReplace: null,
            }),
        );
    };
}

import {defineMessages} from 'react-intl';

import {CONSULTATIONS_CONSULTATIONS_API} from 'apiEndpoints.js';
import batchActions from 'better_consult/actions/batchActions.js';
import actions from 'better_consult/actions/types.js';
import {createStep} from 'consultations/helpers.js';
import http from 'http.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

/*
    Clinical:
    Ask about other symptoms and conditions the patient may have,
    grouped by body system. US only.

    Technical:
    This step is added for each body system returned by the
    review of systems API.

    Dependencies:
    Only shown if there is data returned from ReviewOfSystemsCheckpointStep

*/

export const messages = defineMessages({
    title: {
        id: 'consultations.ReviewOfSystemsStep.title',
        defaultMessage: 'title',
    },
});
export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => {
        const title = props.intl.formatMessage(messages.title, {
            bodySystem: props.name,
        });
        return {title};
    },
    reloadAction: reloadReviewOfSystemsStep,
    submitAction: submitReviewOfSystemsStep,
    undoAction: undoReviewOfSystemsStep,
});

export function reloadReviewOfSystemsStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const currentStepIndex = store.form.steps
            .filter((step) => step.component.type === 'ReviewOfSystemsStep')
            .findIndex((step) => step.id === stepId);
        if (currentStepIndex === -1) {
            return;
        }
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'ReviewOfSystemsStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {
                selectedAnswers,
                reviewOfSystemId,
            } = previousStep.component.props;
            dispatch(
                batchActions(
                    {
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.selectedAnswers',
                            value: selectedAnswers,
                        },
                    },
                    {
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.reviewOfSystemId',
                            value: reviewOfSystemId,
                        },
                    },
                ),
            );
            return {answers: selectedAnswers};
        }
    };
}

export function submitReviewOfSystemsStep({stepId, answers, props}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const state = getState();
            const {bodySystemId} = props;
            const consultationId = state.data.consultations.consultation.id;
            const symptoms = [];
            const conditions = [];
            answers.forEach(({type, id}) => {
                if (type === 'symptom') {
                    symptoms.push(id);
                } else {
                    conditions.push(id);
                }
            });
            const data = {
                symptoms,
                conditions,
            };

            const response = await http.post({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${consultationId}/review-of-systems/${bodySystemId}/`,
                data,
            });

            dispatch(
                batchActions(
                    {
                        type: actions.data.ADD_REVIEW_OF_SYSTEMS,
                        payload: snakeToCamelObjectKeys(response),
                    },
                    {
                        type: actions.data.SET_CONSULTATION_ATTR,
                        payload: {
                            field: 'reviewOfSystems',
                            value: response.id,
                            merge: true,
                        },
                    },
                    {
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.reviewOfSystemId',
                            value: response.id,
                        },
                    },
                    {
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.selectedAnswers',
                            value: answers,
                        },
                    },
                ),
            );
        }
    };
}

export function undoReviewOfSystemsStep({stepId, props}) {
    return async function(dispatch, getState) {
        const state = getState();
        const {consultation} = state.data.consultations;
        const consultationId = consultation.id;
        const {bodySystemId, reviewOfSystemId} = props;

        await http.delete({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${consultationId}/review-of-systems/${bodySystemId}/`,
        });

        dispatch(
            batchActions(
                {
                    type: actions.data.REMOVE_REVIEW_OF_SYSTEMS,
                    payload: bodySystemId,
                },
                {
                    type: actions.data.SET_CONSULTATION_ATTR,
                    payload: {
                        field: 'reviewOfSystems',
                        value: consultation.reviewOfSystems.filter(
                            (item) => item !== reviewOfSystemId,
                        ),
                    },
                },
                {
                    type: actions.form.SET_STEP_ATTR,
                    payload: {
                        stepId,
                        field: 'component.props.selectedAnswers',
                        value: [],
                    },
                },
                {
                    type: actions.form.SET_STEP_ATTR,
                    payload: {
                        stepId,
                        field: 'component.props.reviewOfSystemId',
                        value: undefined,
                    },
                },
            ),
        );
    };
}

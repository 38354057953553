import {defineMessages} from 'react-intl';

import {
    getAnswersLinkedToBodyParts,
    recentChangeAnswers,
    RECENT_CHANGE_SAME,
    RECENT_CHANGE_WORSE,
    translateAnswers,
    PROBLEM_TYPE_SYMPTOM,
    PROBLEM_TYPE_CONDITION,
} from 'clinical/helpers.js';
import {
    updateConsultationSymptom,
    updatePastCondition,
} from 'consultations/actions/consultationActions.js';
import {
    addStep,
    addSteps,
    createConstitutionalSteps,
    constitutionalSymptomsAsked,
} from 'consultations/actions/formActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

export const messages = defineMessages({
    patternTitle: {
        id: 'consultations.RecentChangeStep.patternTitle',
        defaultMessage: 'title',
    },
    existingPatientTitle: {
        id: 'consultations.RecentChangeStep.existingPatientTitle',
        defaultMessage: 'title',
    },
});

/*
    Clinical:
    Has the patient's symptom been getting worse, better or staying the same?

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
        title: store.data.consultations.consultation.isExistingPatient
            ? props.intl.formatMessage(messages.existingPatientTitle)
            : props.intl.formatMessage(messages.patternTitle),
        answers: translateAnswers(recentChangeAnswers, props.intl),
    }),
    reloadAction: reloadRecentChangeStep,
    submitAction: submitRecentChangeStep,
    undoAction: undoRecentChangeStep,
});

export function reloadRecentChangeStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'RecentChangeStep',
            stepId,
        });
        if (previousStep) {
            const {problem} = previousStep.component.props;
            if (problem.type === PROBLEM_TYPE_SYMPTOM) {
                const {consultationSymptomId} = problem;
                // eslint-disable-next-line max-len
                const consultationSymptom =
                    store.data.consultations.consultationSymptoms[
                        consultationSymptomId
                    ];
                if (!consultationSymptom) {
                    return;
                }
                return {
                    answer: {id: consultationSymptom.recentChange},
                };
            } else if (problem.type === PROBLEM_TYPE_CONDITION) {
                const {pastConditionId} = problem;
                // eslint-disable-next-line max-len
                const pastCondition =
                    store.data.consultations.pastConditions[pastConditionId];
                if (!pastCondition) {
                    return;
                }
                return {
                    answer: {id: pastCondition.recentChange},
                };
            }
        }
    };
}

export function submitRecentChangeStep({stepId, props, answer}, reload) {
    return async function(dispatch, getState) {
        const store = getState();
        const {isExistingPatient} = store.data.consultations.consultation;
        const {problem} = props;
        if (problem.type === PROBLEM_TYPE_SYMPTOM) {
            if (isExistingPatient) {
                if (answer.id === RECENT_CHANGE_WORSE.id) {
                    // TODO: insert steps for existing patient
                    const symptom =
                        store.data.clinical.symptoms[problem.symptomId];
                    const steps = [];
                    if (symptom.hasPattern) {
                        steps.push({
                            type: 'PatternStep',
                            props: {problem},
                        });
                    }
                    if (symptom.severityQuestion) {
                        const {
                            answers,
                            allowNoAnswer,
                            name,
                        } = symptom.severityQuestion;
                        steps.push({
                            type: 'SeverityQuestionStep',
                            props: {
                                answers: answers.map(({id, name: text}) => ({
                                    id,
                                    text,
                                })),
                                hasNoAnswerOption: allowNoAnswer,
                                problem,
                                title: name,
                            },
                        });
                    } else if (symptom.hasSeverity) {
                        steps.push({
                            type: 'SeverityStep',
                            props: {
                                problem,
                            },
                        });
                    }
                    if (symptom.hasImpactOnFunction) {
                        const impactsOnFunction = getAnswersLinkedToBodyParts(
                            'impactsOnFunction',
                            symptom,
                        );
                        /* eslint-disable-next-line max-depth */
                        if (impactsOnFunction.length) {
                            steps.push({
                                type: 'ImpactsOnFunctionStep',
                                props: {
                                    answers: impactsOnFunction,
                                    problem,
                                },
                            });
                        }
                    }
                    if (symptom.askConstitutional) {
                        // eslint-disable-next-line max-depth
                        if (!constitutionalSymptomsAsked(store)) {
                            steps.push(
                                ...createConstitutionalSteps(store, problem),
                            );
                        }
                    }
                    if (steps.length) {
                        dispatch(
                            addSteps({
                                steps,
                                parentId: stepId,
                            }),
                        );
                    }
                }
            } else {
                if (answer.id !== RECENT_CHANGE_SAME.id) {
                    dispatch(
                        addStep({
                            type: 'RecentChangeTimeFrameStep',
                            parentId: stepId,
                            props: {problem},
                        }),
                    );
                }
            }
            if (!reload) {
                await dispatch(
                    updateConsultationSymptom(problem.consultationSymptomId, {
                        field: 'recentChange',
                        toReplace: answer.id,
                    }),
                );
            }
        } else if (problem.type === PROBLEM_TYPE_CONDITION) {
            if (!reload) {
                await dispatch(
                    updatePastCondition(problem.pastConditionId, {
                        field: 'recentChange',
                        toReplace: answer.id,
                    }),
                );
            }
        }
    };
}

export function undoRecentChangeStep({props}) {
    return async function(dispatch) {
        const {problem} = props;
        if (problem.type === PROBLEM_TYPE_SYMPTOM) {
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'recentChange',
                    toReplace: null,
                }),
            );
        } else if (problem.type === PROBLEM_TYPE_CONDITION) {
            await dispatch(
                updatePastCondition(problem.pastConditionId, {
                    field: 'recentChange',
                    toReplace: null,
                }),
            );
        }
    };
}

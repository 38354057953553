import {defineMessages} from 'react-intl';

import {CLINICAL_PROBLEMS_SEARCH_API} from 'apiEndpoints.js';
import autocompleteManager from 'autocomplete_manager.js';
import actions from 'better_consult/actions/types.js';
import {
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_FREE_TEXT,
    PROBLEM_TYPE_PRESCRIPTION,
    PROBLEM_TYPE_PRESCRIPTION_RFV,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {
    submitCondition,
    submitFreeTextProblem,
    submitMedicationCourse,
    submitSymptom,
    undoCondition,
    undoFreeTextProblem,
    undoMedicationCourse,
    undoSymptom,
} from 'consultations/actions/consultationActions.js';
import {addStep} from 'consultations/actions/formActions.js';
import {createStep, consultationFeatureFlags} from 'consultations/helpers.js';
import AutoCompleteQuestion from 'questions/components/AutoCompleteQuestion.js';
import capitalise from 'utils/capitalise.js';

const messages = defineMessages({
    disabledResultText: {
        id: 'consultations.ProblemSearchStep.disabledResultText',
        defaultMessage: 'disabledAnswerText',
    },
    helperTitle: {
        id: 'consultations.ProblemSearchStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    helperLabel: {
        id: 'consultations.ProblemSearchStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperContent: {
        id: 'consultations.ProblemSearchStep.helperContent',
        defaultMessage: 'helperContent',
    },
    noAnswerText: {
        id: 'consultations.ProblemSearchStep.noAnswerText',
        defaultMessage: 'noAnswerText',
    },
    questionTitle: {
        id: 'consultations.ProblemSearchStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    questionSecondaryTitle: {
        id: 'consultations.ProblemSearchStep.questionSecondaryTitle',
        defaultMessage: 'questionSecondaryTitle',
    },
    searchPlaceholder: {
        id: 'consultations.ProblemSearchStep.searchPlaceholder',
        defaultMessage: 'searchPlaceholder',
    },
});

/*
    Clinical:
    Allows the patient to search for any problem (i.e. symptom or condition).

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    - We will want to include other reasons for visit with better models in the
    future. Currently Ron is adding Symptoms for things like "travel
    vaccination" and setting non_medical_symptom=True.
*/

export default createStep({
    question: AutoCompleteQuestion,
    props: {
        searchAction: searchProblems,
    },
    mapState: (store, props) => {
        let title;
        let noAnswerText;
        const disabledResultText = props.intl.formatMessage(
            messages.disabledResultText,
        );
        const helper = {
            title: props.intl.formatMessage(messages.helperTitle),
            label: props.intl.formatMessage(messages.helperLabel),
            content: props.intl.formatMessage(messages.helperContent),
        };
        const placeholder = props.intl.formatMessage(
            messages.searchPlaceholder,
        );
        // TODO: add isDisabledResult function here to disable already entered
        // problems from appearing in the search
        if (props.secondary) {
            title = props.intl.formatMessage(messages.questionSecondaryTitle);
            noAnswerText = props.intl.formatMessage(messages.noAnswerText);
        } else {
            title = props.intl.formatMessage(messages.questionTitle);
            noAnswerText = null;
        }
        const {consultations} = store.data;
        const {consultation} = consultations;
        const disabledSymptoms = [];
        for (const id of Object.keys(consultations.consultationSymptoms)) {
            const consultationSymptom = consultations.consultationSymptoms[id];
            if (!consultationSymptom.symptomId) {
                // skip free-text problem
                continue;
            }
            disabledSymptoms.push({
                type: PROBLEM_TYPE_SYMPTOM,
                id: consultationSymptom.symptomId,
            });
        }
        const disabledConditions = [];
        for (const id of consultation.pastConditions) {
            const pastCondition = consultations.pastConditions[id];
            disabledConditions.push({
                type: PROBLEM_TYPE_CONDITION,
                id: pastCondition.conditionId,
            });
        }
        const disabledMedications = [];
        for (const id of consultation.medicationCourses) {
            const {medicationSearchTerm} = consultations.medicationCourses[id];
            if (medicationSearchTerm) {
                disabledMedications.push({
                    type: PROBLEM_TYPE_PRESCRIPTION,
                    id: medicationSearchTerm.id,
                });
            }
        }

        const disabledResults = disabledSymptoms.concat(
            disabledConditions,
            disabledMedications,
        );

        const searchData = {
            consultation: consultation.id,
        };

        const {featureFlags} = store.app;
        const enableFreeText = featureFlags.find(
            // eslint-disable-next-line max-len
            (ff) =>
                ff.name === consultationFeatureFlags.FREE_TEXT_PROBLEM_SEARCH,
        ).enabled;

        return {
            title,
            helper,
            placeholder,
            noAnswerText,
            disabledResults,
            disabledResultText,
            searchData,
            enableFreeText,
        };
    },
    reloadAction: reloadProblemSearchStep,
    submitAction: submitProblemSearchStep,
    undoAction: undoProblemSearchStep,
});

export async function searchProblems(q, data) {
    let results = [];
    if (q) {
        const url = CLINICAL_PROBLEMS_SEARCH_API;
        try {
            results = await autocompleteManager.getResults(url, {q, ...data});
            results = results.map((result) => ({
                id: result.id,
                text: result.name,
                type: result.type,
                synonyms: result.synonyms || [],
            }));
        } catch (err) {
            throw err;
        }
    }
    return results;
}

export function reloadProblemSearchStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const currentStepIndex = store.form.steps
            .filter((step) => step.component.type === 'ProblemSearchStep')
            .findIndex((step) => step.id === stepId);
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'ProblemSearchStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {props} = previousStep.component;
            let answer;
            let result = {};
            if (props.searchData) {
                const {searchResults, searchValue} = props.searchData;
                result = {
                    searchResults,
                    searchValue,
                };
                switch (props.problem.type) {
                    case PROBLEM_TYPE_FREE_TEXT:
                        answer = {
                            text: searchValue,
                            type: PROBLEM_TYPE_FREE_TEXT,
                            freeText: true,
                        };
                        break;
                    case PROBLEM_TYPE_SYMPTOM:
                        answer = {
                            id: props.problem.symptomId,
                            text:
                                store.data.clinical.symptoms[
                                    props.problem.symptomId
                                ].name,
                            type: PROBLEM_TYPE_SYMPTOM,
                            freeText: false,
                        };
                        break;
                    case PROBLEM_TYPE_CONDITION:
                        answer = {
                            id: props.problem.conditionId,
                            text:
                                store.data.clinical.conditions[
                                    props.problem.conditionId
                                ].name,
                            type: PROBLEM_TYPE_CONDITION,
                            freeText: false,
                        };
                        break;
                    case PROBLEM_TYPE_PRESCRIPTION:
                        result.medicationCourse =
                            store.data.consultations.medicationCourses[
                                props.problem.medicationCourseId
                            ];
                        answer = {
                            id: props.problem.medicationSearchTermId,
                            text: props.problem.displayText,
                            type: PROBLEM_TYPE_PRESCRIPTION,
                            freeText: false,
                        };
                        break;
                    case PROBLEM_TYPE_PRESCRIPTION_RFV:
                        answer = {
                            id: props.problem.prescriptionId,
                            text: props.problem.displayText,
                            type: PROBLEM_TYPE_PRESCRIPTION_RFV,
                            freeText: false,
                        };
                        break;
                }
            }
            result.answer = answer;
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.problem',
                    value: props.problem,
                },
            });
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.searchData',
                    value: props.searchData,
                },
            });
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.shouldAddSteps',
                    value: props.shouldAddSteps,
                },
            });
            return result;
        }
    };
}

export function submitProblemSearchStep(
    {stepId, answer, medicationCourse, searchResults, searchValue},
    reload,
) {
    return async function (dispatch) {
        if (answer) {
            // Add an additional ProblemSearchStep via the RepeatStep when an
            // answer is provided
            dispatch(
                addStep({
                    type: 'RepeatStep',
                    props: {
                        helperOpenByDefault: true,
                        helperKey: 'problemSearchStep',
                        titleKey: 'problemSearchStepSecondaryTitle',
                        step: {
                            type: 'ProblemSearchStep',
                            props: {
                                secondary: true,
                            },
                        },
                    },
                    parentId: stepId,
                }),
            );
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.searchData',
                    value: {
                        searchValue,
                        searchResults,
                    },
                },
            });
            // Add steps that are relevant to the type of problem
            if (answer.freeText) {
                if (searchResults.length === 1 || searchValue.length > 100) {
                    await dispatch(
                        submitFreeTextProblem(
                            {
                                stepId,
                                text: answer.text,
                            },
                            reload,
                        ),
                    );
                } else if (
                    searchResults.length > 1 &&
                    searchValue.length <= 100
                ) {
                    dispatch({
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.problem',
                            value: {
                                type: PROBLEM_TYPE_FREE_TEXT,
                            },
                        },
                    });
                    dispatch(
                        addStep({
                            type: 'FreeTextProblemConfirmStep',
                            props: {
                                answers: searchResults.filter(
                                    (result) => !result.freeText,
                                ),
                                disabledAnswers: searchResults
                                    .filter((result) => result.disabled)
                                    .map((result) => result.id),
                                searchValue: capitalise(
                                    searchValue,
                                    'sentence',
                                ),
                            },
                            parentId: stepId,
                        }),
                    );
                }
            } else {
                let displayText;
                if (!reload) {
                    displayText = answer.synonyms.find((synonym) => {
                        const synonymText = synonym.toLowerCase();
                        if (synonymText.includes(searchValue.toLowerCase())) {
                            return answer.text
                                .toLowerCase()
                                .includes(synonymText);
                        }
                        return false;
                    });
                }
                switch (answer.type.toLowerCase()) {
                    case PROBLEM_TYPE_SYMPTOM:
                        await dispatch(
                            submitSymptom(
                                {
                                    stepId,
                                    symptomId: answer.id,
                                    displayText,
                                },
                                reload,
                            ),
                        );
                        break;
                    case PROBLEM_TYPE_CONDITION:
                        await dispatch(
                            submitCondition(
                                {
                                    stepId,
                                    conditionId: answer.id,
                                    displayText,
                                },
                                reload,
                            ),
                        );
                        break;
                    case PROBLEM_TYPE_PRESCRIPTION:
                        await dispatch(
                            submitMedicationCourse(
                                {
                                    stepId,
                                    medicationSearchTermId: answer.id,
                                    medicationCourse,
                                    isPrescriptionReasonForVisit: true,
                                    displayText,
                                },
                                reload,
                            ),
                        );
                        break;
                    case PROBLEM_TYPE_PRESCRIPTION_RFV:
                        if (!reload) {
                            dispatch({
                                type: actions.form.SET_STEP_ATTR,
                                payload: {
                                    stepId,
                                    field: 'component.props.problem',
                                    value: {
                                        type: PROBLEM_TYPE_PRESCRIPTION_RFV,
                                        prescriptionId: answer.id,
                                        displayText,
                                    },
                                },
                            });
                        }
                        dispatch(
                            addStep({
                                type: 'MedicationForPrescriptionStep',
                                parentId: stepId,
                            }),
                        );
                        break;
                }
            }
        }
    };
}

export function undoProblemSearchStep({
    stepId,
    answer,
    searchResults,
    searchValue,
}) {
    return async function (dispatch) {
        if (answer) {
            dispatch({
                type: actions.form.SET_STEP_ATTR,
                payload: {
                    stepId,
                    field: 'component.props.searchData',
                    value: undefined,
                },
            });
            if (answer.freeText) {
                if (searchResults.length === 1 || searchValue.length > 100) {
                    await dispatch(undoFreeTextProblem(stepId));
                } else if (
                    searchResults.length > 1 &&
                    searchValue.length <= 100
                ) {
                    dispatch({
                        type: actions.form.SET_STEP_ATTR,
                        payload: {
                            stepId,
                            field: 'component.props.problem',
                            value: undefined,
                        },
                    });
                }
            } else {
                switch (answer.type.toLowerCase()) {
                    case PROBLEM_TYPE_SYMPTOM:
                        await dispatch(undoSymptom(stepId));
                        break;
                    case PROBLEM_TYPE_CONDITION:
                        await dispatch(undoCondition(stepId));
                        break;
                    case PROBLEM_TYPE_PRESCRIPTION:
                        await dispatch(undoMedicationCourse({stepId}));
                        break;
                    case PROBLEM_TYPE_PRESCRIPTION_RFV:
                        dispatch({
                            type: actions.form.SET_STEP_ATTR,
                            payload: {
                                stepId,
                                field: 'component.props.problem',
                                value: undefined,
                            },
                        });
                        break;
                }
            }
        }
    };
}

import moment from 'moment';
import {defineMessages} from 'react-intl';

import {
    _oldCreatePatient,
    removePatient,
    selectPatient,
} from 'accounts/actions/accountsActions.js';
import {addSteps, setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    selectionText: {
        id: 'consultations.PatientSelectionStep.selectionText',
        defaultMessage: 'selectionText',
    },
    newPatientText: {
        id: 'consultations.PatientSelectionStep.newPatientText',
        defaultMessage: 'newPatientText',
    },
    unnamedPatient: {
        id: 'consultations.PatientSelectionStep.unnamedPatient',
        defaultMessage: 'unnamedPatient',
    },
});

/*
    Clinical:
    N/A

    Technical:
    It's possible that one account may be used for multiple patients (e.g.
    parent or guardian).

    This step is only added when the user already has a completed consultation
    and a patient on their BC account. The list only shows patients who have
    already completed consultations. If the patient says they're a new patient,
    we then add the PatientNameStep to the work flow.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => {
        const patients = store.accounts.user.patients.items
            .filter(
                (patient) =>
                    patient.claimed && patient.id !== props.newPatientId,
            )
            .map((patient) => {
                let text;
                if (patient.firstName && patient.lastName) {
                    text = `${patient.firstName} ${patient.lastName}`;
                } else if (patient.firstName) {
                    text = patient.firstName;
                } else if (patient.lastName) {
                    text = patient.lastName;
                } else {
                    text = props.intl.formatMessage(messages.unnamedPatient);
                }
                if (patient.birthDate) {
                    const birthDate = moment(patient.birthDate).format(
                        'DD-MM-YYYY',
                    );
                    text += ` (${birthDate})`;
                }
                const id = patient.id;
                return {
                    text,
                    id,
                };
            });
        patients.push({
            text: props.intl.formatMessage(messages.newPatientText),
            id: props.newPatientId,
        });
        return {
            answers: patients,
            title: props.intl.formatMessage(messages.selectionText),
        };
    },
    reloadAction: reloadPatientSelectionStep,
    submitAction: submitPatientSelectionStep,
    undoAction: undoPatientSelectionStep,
});

export function reloadPatientSelectionStep() {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = store.form.previousSteps.find(
            (step) => step.component.type === 'PatientSelectionStep',
        );
        if (previousStep) {
            const patientId =
                store.data.accounts.patient.id ||
                store.data.consultations.consultation.patientId;
            return {
                answer: {id: patientId},
            };
        }
    };
}

export function submitPatientSelectionStep({stepId, answer, props}, reload) {
    return async function(dispatch, getState) {
        const store = getState();
        const {mustComplyWithHipaa, newPatientId} = props;
        const {isExistingPatient} = store.data.consultations.consultation;
        // TODO: remove default patientId answer from consultation store below
        // once the initial data load populations the patient store
        const patientId =
            store.data.accounts.patient.id ||
            store.data.consultations.consultation.patientId;
        const selectedPatient = store.accounts.user.patients.items.find(
            (patient) => patient.id === answer.id || patient.id === patientId,
        );
        if (!reload) {
            if (answer.id === newPatientId) {
                await dispatch(selectPatient(patientId));
            } else {
                await dispatch(selectPatient(answer.id));
                // Remove the new patient originally created for this
                // consultation
                await dispatch(removePatient(patientId));
            }
        }
        const steps = [
            {
                type: 'PatientNameStep',
                props: {
                    firstName: selectedPatient.firstName,
                    lastName: selectedPatient.lastName,
                    mustComplyWithHipaa,
                },
            },
        ];
        if (isExistingPatient) {
            if (!selectedPatient.sex) {
                steps.push({type: 'DemographicsSexStep'});
            }
            if (!selectedPatient.birthDate) {
                if (mustComplyWithHipaa) {
                    steps.push({type: 'DemographicsAgeStep'});
                } else {
                    steps.push({type: 'DemographicsDateOfBirthStep'});
                }
            }
        }
        dispatch(
            addSteps({
                steps,
                parentId: stepId,
            }),
        );
    };
}

export function undoPatientSelectionStep({stepId, answer, props}) {
    return async function(dispatch, getState) {
        const store = getState();
        const {isDemo} = store.data.consultations.consultation;
        const {newPatientId} = props;
        // if the new patient was not the answer beign undone then we want to
        // recreate the new patient and select it
        // TODO: when undoing the new patient its claimed field should be set
        // to false
        if (answer.id !== newPatientId) {
            const patientId = await dispatch(_oldCreatePatient(isDemo));
            await dispatch(selectPatient(patientId));
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'newPatientId',
                    value: patientId,
                }),
            );
        }
    };
}

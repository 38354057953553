import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages} from 'react-intl';

import {pushHistory} from 'better_consult/components/History.js';
import {completeConsultation} from 'consultations/actions/consultationActions.js';
import {createStep} from 'consultations/helpers.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    completedButtonText: {
        id: 'consultations.SubmitConsultationStep.completedButtonText',
        defaultMessage: 'completedButtonText',
    },
    completedDemoButtonText: {
        id: 'consultations.SubmitConsultationStep.completedDemoButtonText',
        defaultMessage: 'completedDemoButtonText',
    },
    completedTitle: {
        id: 'consultations.SubmitConsultationStep.completedTitle',
        defaultMessage: 'completedTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    If this is a demo consultation, we redirect straight to the dashboard to
    show the output, otherwise we logout.

    Dependencies:
    N/A

    Future:
    N/A
*/

export class CompletedConsultationQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        consultation: PropTypes.object.isRequired,
        submitAction: PropTypes.func.isRequired,
    };

    render() {
        const intl = this.props.intl;
        const buttonMessage = this.props.consultation.isDemo
            ? messages.completedDemoButtonText
            : messages.completedButtonText;
        return (
            <Question
                {...genericProps(this.props)}
                reloadAction={this.props.reloadAction}
                title={intl.formatMessage(messages.completedTitle)}
            >
                <AnswerSubmitButton
                    buttonText={intl.formatMessage(buttonMessage)}
                    dataTestId="continue-button"
                    submitAnswers={this.props.submitAction}
                />
            </Question>
        );
    }
}

export default createStep({
    question: CompletedConsultationQuestion,
    submitAction: submitSubmitConsultationStep,
    reloadAction: reloadSubmitConsultationStep,
});

export function reloadSubmitConsultationStep() {
    return function () {
        return false;
    };
}

export function submitSubmitConsultationStep({props}) {
    return async function (dispatch) {
        const {id, isDemo} = props.consultation;

        try {
            await dispatch(completeConsultation(id));
        } catch (error) {
            return;
        }
        if (isDemo) {
            const redirectPath = `/dashboard/consultations/${id}/`;
            window.location.assign(redirectPath);
        } else {
            pushHistory('/consultations/consultation-success/');
        }
    };
}

import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.TriggerEventsStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    Determine whether there are specific events/actions which trigger the
    symptom.

    Technical:
    Only shown if the Symptom has trigger_events

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadTriggerEventsStep,
    submitAction: submitTriggerEventsStep,
    undoAction: undoTriggerEventsStep,
});

export function reloadTriggerEventsStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'TriggerEventsStep',
            stepId,
        });
        if (previousStep) {
            const {answers, problem} = previousStep.component.props;
            const factors =
                store.data.consultations.consultationSymptoms[
                    problem.consultationSymptomId
                ].triggerEvents;
            if (factors) {
                const selectedAnswers = answers.filter((answer) =>
                    factors.some((factor) => answer.id === factor.id),
                );
                return {
                    answers: selectedAnswers,
                };
            }
        }
    };
}

export function submitTriggerEventsStep({stepId, answers}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {consultationSymptomId} = step.component.props.problem;
            let toAdd = [];
            if (answers.length) {
                toAdd = answers.map((answer) => answer.id);
                await dispatch(
                    updateConsultationSymptom(consultationSymptomId, {
                        field: 'triggerEvents',
                        toAdd,
                    }),
                );
            }
        }
    };
}

export function undoTriggerEventsStep({stepId, answers}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {consultationSymptomId} = step.component.props.problem;

        if (answers.length) {
            const toRemove = answers.map((answer) => answer.id);
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'triggerEvents',
                    toRemove,
                }),
            );
        }
    };
}

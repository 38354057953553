import {defineMessages} from 'react-intl';

import {
    recentChangeTimeFrameAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

const messages = defineMessages({
    placeholder: {
        id: 'consultations.RecentChangeTimeFrameStep.placeholder',
        defaultMessage: 'placeholder',
    },
    title: {
        id: 'consultations.RecentChangeTimeFrameStep.title',
        defaultMessage: 'title',
    },
    trailingText: {
        id: 'consultations.RecentChangeTimeFrameStep.trailingText',
        defaultMessage: 'trailingText',
    },
});

/*
    Clinical:
    If the symptom is changing (getting better or worse), how long ago did it
    start changing?

    Technical:
    N/A

    Dependencies:
    Only shown if the patient selects "better" or "worse" from the
    RecentChangeStep

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    props: {
        minValue: 0,
    },
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
        trailingText: props.intl.formatMessage(messages.trailingText),
        options: translateAnswers(recentChangeTimeFrameAnswers, props.intl),
    }),
    reloadAction: reloadRecentChangeTimeFrameStep,
    submitAction: submitRecentChangeTimeFrameStep,
    undoAction: undoRecentChangeTimeFrameStep,
});

export function reloadRecentChangeTimeFrameStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'RecentChangeTimeFrameStep',
            stepId,
        });
        if (previousStep) {
            const {
                consultationSymptomId,
            } = previousStep.component.props.problem;
            const {
                periodSinceRecentChange,
                recentChangeTimeFrame,
            } = store.data.consultations.consultationSymptoms[
                consultationSymptomId
            ];
            return {
                answer: {
                    num: String(periodSinceRecentChange),
                    option: recentChangeTimeFrame,
                },
            };
        }
    };
}

export function submitRecentChangeTimeFrameStep({props, answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const {consultationSymptomId} = props.problem;
            // TODO: improve updateConsultationSymptom to be able to take in an
            // array of fields that we want to update
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'periodSinceRecentChange',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'recentChangeTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoRecentChangeTimeFrameStep({props}) {
    return async function(dispatch) {
        const {consultationSymptomId} = props.problem;
        // TODO: improve updateConsultationSymptom to be able to take in an
        // array of fields that we want to update
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'periodSinceRecentChange',
                toReplace: null,
            }),
        );
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'recentChangeTimeFrame',
                toReplace: null,
            }),
        );
    };
}

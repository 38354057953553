import {defineMessages} from 'react-intl';

import actions from 'better_consult/actions/types.js';
import {
    addStepsForCondition,
    addStepsForUndiagnosedCondition,
} from 'consultations/actions/formActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.PastConditionHasBeenDiagnosedStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    no: {
        id: 'consultations.PastConditionHasBeenDiagnosedStep.no',
        defaultMessage:
            'I think I have this condition, but I was not diagnosed by a doctor',
    },
    yes: {
        id: 'consultations.PastConditionHasBeenDiagnosedStep.yes',
        defaultMessage: 'I was diagnosed by a doctor',
    },
});

/*
    Clinical:
    Asks whether the patient has been diagnosed with a condition.

    Technical:
    N/A

    Dependencies:
    This step is inserted if the user selects a condition from the
    ProblemSearchStep.

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        answers: [
            {
                id: 'yes',
                text: props.intl.formatMessage(messages.yes),
            },
            {
                id: 'no',
                text: props.intl.formatMessage(messages.no),
            },
        ],
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadPastConditionHasBeenDiagnosedStep,
    submitAction: submitPastConditionHasBeenDiagnosedStep,
});

export function reloadPastConditionHasBeenDiagnosedStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'PastConditionHasBeenDiagnosedStep',
            stepId,
        });
        if (previousStep) {
            const {answer} = previousStep.component.props;
            const selectedSymptomIds = Object.values(
                getState().data.consultations.consultationSymptoms,
            ).map((cs) => cs.symptomId);
            return {answer, selectedSymptomIds};
        }
    };
}

export function submitPastConditionHasBeenDiagnosedStep(
    {stepId, answer, selectedSymptomIds, props},
    reload,
) {
    return async function (dispatch, getState) {
        const data = {
            stepId,
            problem: props.problem,
        };
        if (reload) {
            data.selectedSymptomIds = selectedSymptomIds;
        }
        if (answer.id === 'yes') {
            dispatch(addStepsForCondition(data));
        } else {
            dispatch(addStepsForUndiagnosedCondition(data));
        }
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.answer',
                value: answer,
            },
        });
    };
}

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import App from 'better_consult/components/App.js';
import {updateComponentsList} from 'componentsList.js';
import {componentsList} from 'consultations/componentsList.js';
import {routes} from 'consultations/routes.js';
import IntlProvider from 'IntlProvider.js';
import rehydrateStore from 'utils/rehydrate_store.js';
import intlPolyfill from 'intl_polyfill.js';

updateComponentsList(componentsList);

(async function() {
    await intlPolyfill();
    const store = await rehydrateStore();
    ReactDOM.render(
        <Provider store={store}>
            <IntlProvider>
                <App routes={routes} />
            </IntlProvider>
        </Provider>,
        document.getElementById('app'),
    );
})();

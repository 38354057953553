import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionText: {
        id: 'consultations.SeverityAtWorstStep.questionText',
        defaultMessage: 'questionText',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    Only shown if the patient selects >0 and <10 in the SeverityStep

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    props: {
        rangeType: true,
    },
    mapState: (store, props) => {
        let disabledAnswers = [];
        const defaultRange = [...Array(11).keys()];
        if (defaultRange.indexOf(props.severity) !== -1) {
            disabledAnswers = defaultRange.filter(
                (answer) => answer < props.severity,
            );
        }
        return {
            disabledAnswers,
            subTitle: props.problem.displayText,
            title: props.intl.formatMessage(messages.questionText, {
                severity: props.severity,
            }),
        };
    },
    reloadAction: reloadSeverityAtWorstStep,
    submitAction: submitSeverityAtWorstStep,
    undoAction: undoSeverityAtWorstStep,
});

export function reloadSeverityAtWorstStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'SeverityAtWorstStep',
            stepId,
        });
        if (previousStep) {
            const {problem} = previousStep.component.props;
            const {consultationSymptoms} = store.data.consultations;
            const severityAtWorst =
                consultationSymptoms[problem.consultationSymptomId]
                    .severityAtWorst;
            return {
                answer: {
                    id: severityAtWorst,
                },
            };
        }
    };
}

export function submitSeverityAtWorstStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {consultationSymptomId} = step.component.props.problem;
            await dispatch(
                updateConsultationSymptom(consultationSymptomId, {
                    field: 'severityAtWorst',
                    toReplace: answer.id,
                }),
            );
        }
    };
}

export function undoSeverityAtWorstStep({stepId}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {consultationSymptomId} = step.component.props.problem;
        await dispatch(
            updateConsultationSymptom(consultationSymptomId, {
                field: 'severityAtWorst',
                toReplace: null,
            }),
        );
    };
}

import {defineMessages} from 'react-intl';

import {CLINICAL_CONDITIONS_SEARCH_API} from 'apiEndpoints.js';
import autocompleteManager from 'autocomplete_manager.js';
import {
    createPastConditions,
    deletePastConditions,
} from 'consultations/actions/consultationActions.js';
import {addStep, setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import AutoCompleteQuestion from 'questions/components/AutoCompleteQuestion.js';

export const messages = defineMessages({
    disabledResultText: {
        id: 'consultations.PastConditionStep.disabledResultText',
        defaultMessage: 'disabledResultText',
    },
    helperTitle: {
        id: 'consultations.PastConditionStep.helperTitle',
        defaultMessage: 'helperTitle',
    },
    helperLabel: {
        id: 'consultations.PastConditionStep.helperLabel',
        defaultMessage: 'helperLabel',
    },
    helperContent: {
        id: 'consultations.PastConditionStep.helperContent',
        defaultMessage: 'helperContent',
    },
    title: {
        id: 'consultations.PastConditionStep.title',
        defaultMessage: 'title',
    },
    secondaryTitle: {
        id: 'consultations.PastConditionStep.secondaryTitle',
        defaultMessage: 'secondaryTitle',
    },
    searchPlaceholder: {
        id: 'consultations.PastConditionStep.searchPlaceholder',
        defaultMessage: 'searchPlaceholder',
    },
    noAnswerText: {
        id: 'consultations.PastConditionStep.noAnswerText',
        defaultMessage: 'noAnswerText',
    },
    noOtherAnswerText: {
        id: 'consultations.PastConditionStep.noOtherAnswerText',
        defaultMessage: 'noOtherAnswerText',
    },
});

/*
    Clinical:
    Has the patient ever been diagnosed with other conditions in the past that
    are not related to any presenting symptoms?

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: AutoCompleteQuestion,
    props: {
        searchAction: searchConditions,
    },
    mapState: (store, props) => {
        const {
            consultation,
            pastConditions,
            medicationCourses,
        } = store.data.consultations;
        let disabledResults = consultation.pastConditions.map((id) => ({
            id: pastConditions[id].conditionId,
        }));
        const newPrescriptionConditionIds = [];
        consultation.medicationCourses.forEach((id) => {
            const mc = medicationCourses[id];
            if (mc.currentlyTaking) {
                disabledResults.push(
                    ...mc.conditions.map((condition) => ({
                        id: condition.id,
                    })),
                );
            } else {
                newPrescriptionConditionIds.push(
                    ...mc.conditions.map((condition) => condition.id),
                );
            }
        });
        disabledResults = disabledResults.filter(
            (result) => !newPrescriptionConditionIds.includes(result.id),
        );
        return {
            disabledResults,
            disabledResultText: props.intl.formatMessage(
                messages.disabledResultText,
            ),
            helper: {
                title: props.intl.formatMessage(messages.helperTitle),
                label: props.intl.formatMessage(messages.helperLabel),
                content: props.intl.formatMessage(messages.helperContent),
            },
            noAnswerText: props.secondary
                ? props.intl.formatMessage(messages.noOtherAnswerText)
                : props.intl.formatMessage(messages.noAnswerText),
            placeholder: props.intl.formatMessage(messages.searchPlaceholder),
            title: props.secondary
                ? props.intl.formatMessage(messages.secondaryTitle)
                : props.intl.formatMessage(messages.title),
        };
    },
    reloadAction: reloadPastConditionStep,
    submitAction: submitPastConditionStep,
    undoAction: undoPastConditionStep,
});

export async function searchConditions(q) {
    let results = [];
    if (q) {
        const url = CLINICAL_CONDITIONS_SEARCH_API;
        try {
            results = await autocompleteManager.getResults(url, {
                q,
                filter: 'past-condition',
            });
            results = results.map((result) => ({
                id: result.id,
                text: result.name,
            }));
        } catch (err) {
            throw err;
        }
    }
    return results;
}

export function submitPastConditionStep(
    {stepId, answer, pastCondition, searchResults, searchValue},
    reload,
) {
    return async function (dispatch) {
        let response = [];
        if (answer) {
            if (!reload) {
                response = await dispatch(
                    createPastConditions([{id: answer.id}]),
                );
            }
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'pastCondition',
                    value: response.length ? response[0].id : pastCondition,
                }),
            );

            dispatch(
                addStep({
                    type: 'RepeatStep',
                    props: {
                        titleKey: 'pastConditionStepSecondaryTitle',
                        step: {
                            type: 'PastConditionStep',
                            props: {
                                secondary: true,
                            },
                        },
                    },
                    parentId: stepId,
                }),
            );
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'searchData',
                    value: {
                        searchValue,
                        searchResults,
                    },
                }),
            );
        }
    };
}

export function undoPastConditionStep({stepId, props}) {
    return async function (dispatch) {
        if (props.pastCondition) {
            await dispatch(deletePastConditions([props.pastCondition]));
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'pastCondition',
                    value: undefined,
                }),
            );
        }
    };
}

export function reloadPastConditionStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const pastConditionSteps = store.form.steps.filter(
            (step) => step.component.type === 'PastConditionStep',
        );
        const currentStepIndex = pastConditionSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'PastConditionStep',
        );
        const previousStep = previousSteps[currentStepIndex];

        if (previousStep) {
            const {
                pastCondition,
                searchData: {searchResults, searchValue} = {},
            } = previousStep.component.props;
            let result = {
                answer: null,
            };
            if (pastCondition) {
                const conditionId =
                    store.data.consultations.pastConditions[pastCondition]
                        .conditionId;
                result = {
                    answer: {
                        text: searchResults.find((sr) => sr.id === conditionId)
                            .text,
                    },
                    pastCondition,
                    searchResults,
                    searchValue,
                };
            }
            return result;
        }
    };
}

import {defineMessages} from 'react-intl';

import {addStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';
import {deepClone, deepEqual} from 'utils/deep_merge.js';

export const messages = defineMessages({
    yesAnswer: {
        id: 'consultations.RepeatStep.yesAnswer',
        defaultMessage: 'yesAnswer',
    },
    noAnswer: {
        id: 'consultations.RepeatStep.noAnswer',
        defaultMessage: 'noAnswer',
    },
    problemSearchStepHelperContent: {
        id: 'consultations.RepeatStep.problemSearchStepHelperContent',
        defaultMessage: 'problemSearchStepHelperContent',
    },
    problemSearchStepHelperLabel: {
        id: 'consultations.RepeatStep.problemSearchStepHelperLabel',
        defaultMessage: 'problemSearchStepHelperLabel',
    },
    problemSearchStepSecondaryTitle: {
        id: 'consultations.RepeatStep.problemSearchStepSecondaryTitle',
        defaultMessage: 'problemSearchStepSecondaryTitle',
    },
    medicationStepTitle: {
        id: 'consultations.RepeatStep.medicationStepTitle',
        defaultMessage: 'medicationStepTitle',
    },
    medicationStepSecondaryTitle: {
        id: 'consultations.RepeatStep.medicationStepSecondaryTitle',
        defaultMessage: 'medicationStepSecondaryTitle',
    },
    pastConditionStepTitle: {
        id: 'consultations.RepeatStep.pastConditionStepTitle',
        defaultMessage: 'pastConditionStepTitle',
    },
    pastConditionStepSecondaryTitle: {
        id: 'consultations.RepeatStep.pastConditionStepSecondaryTitle',
        defaultMessage: 'pastConditionStepSecondaryTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    This step is used to prompt the user with a yes or no question to decide
    whether additional steps need to be added. If the user selects the yes
    answer then a step is added after this step, the details of which are
    found on the store for this step.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        answers: [
            {
                id: 'yes',
                text: props.intl.formatMessage(messages.yesAnswer),
            },
            {
                id: 'no',
                text: props.intl.formatMessage(messages.noAnswer),
            },
        ],
        title: props.intl.formatMessage(messages[props.titleKey]),
        helper: props.helperKey
            ? {
                  content: messages[`${props.helperKey}HelperContent`]
                      ? props.intl.formatMessage(
                            messages[`${props.helperKey}HelperContent`],
                        )
                      : null,
                  label: messages[`${props.helperKey}HelperLabel`]
                      ? props.intl.formatMessage(
                            messages[`${props.helperKey}HelperLabel`],
                        )
                      : null,
                  title: messages[`${props.helperKey}HelperTitle`]
                      ? props.intl.formatMessage(
                            messages[`${props.helperKey}HelperTitle`],
                        )
                      : null,
              }
            : null,
        helperOpenByDefault: props.helperOpenByDefault,
    }),
    reloadAction: reloadRepeatStep,
    submitAction: submitRepeatStep,
});

function matchRepeatSteps(steps, currentStep) {
    return steps.filter(
        (step) =>
            step.component.type === 'RepeatStep' &&
            deepEqual(
                step.component.props.step,
                currentStep.component.props.step,
            ),
    );
}

export function reloadRepeatStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const currentStep = store.form.steps.find(
            (step) => step.id === stepId,
        );
        const currentStepIndex = matchRepeatSteps(
            store.form.steps,
            currentStep,
        ).findIndex((step) => step.id === stepId);
        const previousSteps = matchRepeatSteps(
            store.form.previousSteps,
            currentStep,
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            return {
                answer: {
                    id: previousStep.children.length ? 'yes' : 'no',
                },
            };
        }
    };
}

export function submitRepeatStep({stepId, answer}) {
    return function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        if (answer.id === 'yes') {
            dispatch(
                addStep({
                    ...deepClone(step.component.props.step),
                    parentId: stepId,
                }),
            );
        }
    };
}

import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';

import {createStep} from 'consultations/helpers.js';
import AnswerSubmitButton from 'questions/components/AnswerSubmitButton.js';
import Question, {genericProps} from 'questions/components/Question.js';
import {genericQuestionPropTypes} from 'utils/propTypesShapes.js';

const messages = defineMessages({
    buttonText: {
        id: 'consultations.RelatedSymptomsScreenStep.buttonText',
        defaultMessage: 'buttonText',
    },
    title: {
        id: 'consultations.RelatedSymptomsScreenStep.title',
        defaultMessage: 'title',
    },
    only: {
        id: 'consultations.RelatedSymptomsScreenStep.only',
        defaultMessage: 'only',
    },
    relatedReason: {
        id: 'consultations.RelatedSymptomsScreenStep.relatedReason',
        defaultMessage: 'relatedReason',
    },
});

/*
    Clinical:
    This step is always included before displaying related medical symptoms.
    No real clinical application, just a step to explain related symptoms
    to the user.

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export class RelatedSymptomsScreenQuestion extends React.Component {
    static propTypes = {
        ...genericQuestionPropTypes,
        submitAction: PropTypes.func.isRequired,
        undoAction: PropTypes.func.isRequired,
    };

    render() {
        const intl = this.props.intl;
        return (
            <Question
                {...genericProps(this.props)}
                reloadAction={this.props.reloadAction}
                undoAction={this.props.undoAction}
            >
                <div className="text-block" data-test-id="prompt-text-block">
                    <p>
                        <strong>{intl.formatMessage(messages.title)}</strong>
                    </p>
                    <p>
                        <FormattedMessage
                            // eslint-disable-next-line max-len
                            defaultMessage="consultations.RelatedSymptomsScreenStep.content"
                            // eslint-disable-next-line max-len
                            id="consultations.RelatedSymptomsScreenStep.content"
                            values={{
                                only: (
                                    <strong>
                                        {intl.formatMessage(messages.only)}
                                    </strong>
                                ),
                                relatedReason: (
                                    <strong>
                                        {intl.formatMessage(
                                            messages.relatedReason,
                                        )}
                                    </strong>
                                ),
                            }}
                        />
                    </p>
                </div>
                <AnswerSubmitButton
                    buttonText={intl.formatMessage(messages.buttonText)}
                    submitAnswers={this.props.submitAction}
                />
            </Question>
        );
    }
}

export default createStep({
    question: RelatedSymptomsScreenQuestion,
    reloadAction: reloadRelatedSymptomsScreenStep,
});

export function reloadRelatedSymptomsScreenStep() {
    return function (dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'RelatedSymptomsScreenStep',
        );
        if (previousStep) {
            return {};
        }
    };
}

import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    title: {
        id: 'consultations.RelievingFactorsStep.title',
        defaultMessage: 'title',
    },
});

/*
    Clinical:
    Things that make the symptom better.

    Technical:
    Only shown if there are relieving factors on the Symptom

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.title),
        subTitle: props.problem.displayText,
    }),
    reloadAction: reloadRelievingFactors,
    submitAction: submitRelievingFactors,
    undoAction: undoRelievingFactors,
});

export function reloadRelievingFactors(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'RelievingFactorsStep',
            stepId,
        });
        if (previousStep) {
            const {answers, problem} = previousStep.component.props;
            const factors =
                store.data.consultations.consultationSymptoms[
                    problem.consultationSymptomId
                ].relievingFactors;
            if (factors) {
                const selectedAnswers = answers.filter((answer) =>
                    factors.some((factor) => answer.id === factor.id),
                );
                return {
                    answers: selectedAnswers,
                };
            }
        }
    };
}

export function submitRelievingFactors({stepId, answers}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {problem} = step.component.props;
            let toAdd = [];
            if (answers) {
                toAdd = answers.map((answer) => answer.id);
            }
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'relievingFactors',
                    toAdd,
                }),
            );
        }
    };
}

export function undoRelievingFactors({stepId, answers}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        let toRemove = [];
        if (answers) {
            toRemove = answers.map((answer) => answer.id);
        }
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'relievingFactors',
                toRemove,
            }),
        );
    };
}

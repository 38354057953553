import {defineMessages} from 'react-intl';

import {
    getClinicalSymptom,
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {
    updateConsultationSymptom,
    updatePastCondition,
} from 'consultations/actions/consultationActions.js';
import {
    addSteps,
    addStepsForCondition,
    addStepsForFreeTextProblem,
    addStepsForSymptom,
} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.ProblemSeenBeforeStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    yesAnswer: {
        id: 'consultations.ProblemSeenBeforeStep.yesAnswer',
        defaultMessage: 'yesAnswer',
    },
    noAnswer: {
        id: 'consultations.ProblemSeenBeforeStep.noAnswer',
        defaultMessage: 'noAnswer',
    },
});

/*
    Clinical:
    N/A

    Technical:
    If a patient has already seen a doctor about this problem previously then
    they get a reduced problem workflow following up on the problem, otherwise
    they are taken through the regular problem workflow.

    Dependencies:
    Only ask this question if the patient is an existing patient at the
    practice.

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        answers: [
            {
                id: 'yes',
                text: props.intl.formatMessage(messages.yesAnswer),
            },
            {
                id: 'no',
                text: props.intl.formatMessage(messages.noAnswer),
            },
        ],
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadProblemSeenBeforeStep,
    submitAction: submitProblemSeenBeforeStep,
    undoAction: undoProblemSeenBeforeStep,
});

export function reloadProblemSeenBeforeStep(stepId) {
    return function(dispatch, getState) {
        let seenBefore;
        const store = getState();
        const problemSeenBeforeSteps = store.form.steps.filter(
            (step) => step.component.type === 'ProblemSeenBeforeStep',
        );
        const currentStepIndex = problemSeenBeforeSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'ProblemSeenBeforeStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (!previousStep) {
            return;
        }
        const {problem} = previousStep.component.props;
        if (problem.type === PROBLEM_TYPE_SYMPTOM) {
            seenBefore =
                store.data.consultations.consultationSymptoms[
                    problem.consultationSymptomId
                ].seenBefore;
        } else if (problem.type === PROBLEM_TYPE_CONDITION) {
            seenBefore =
                store.data.consultations.pastConditions[
                    problem.pastConditionId
                ].seenBefore;
        } else {
            seenBefore =
                store.data.consultations.consultationSymptoms[
                    problem.freeTextProblemId
                ].seenBefore;
        }
        return {
            answer: {
                id: seenBefore ? 'yes' : 'no',
            },
        };
    };
}

export function submitProblemSeenBeforeStep({stepId, answer}, reload) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        const seenBefore = answer.id === 'yes';
        if (problem.type === PROBLEM_TYPE_SYMPTOM) {
            if (!reload) {
                await dispatch(
                    updateConsultationSymptom(problem.consultationSymptomId, {
                        field: 'seenBefore',
                        toReplace: seenBefore,
                    }),
                );
            }
            if (seenBefore) {
                const symptom = getClinicalSymptom(store, problem.symptomId);
                if (!symptom.isNonMedical) {
                    dispatch(
                        addSteps({
                            steps: [
                                {
                                    type: 'RecentChangeStep',
                                    props: {
                                        problem,
                                    },
                                },
                            ],
                            parentId: stepId,
                        }),
                    );
                }
            } else {
                dispatch(
                    addStepsForSymptom({
                        stepId,
                        problem,
                        isNewProblem: true,
                    }),
                );
            }
        } else if (problem.type === PROBLEM_TYPE_CONDITION) {
            if (!reload) {
                await dispatch(
                    updatePastCondition(problem.pastConditionId, {
                        field: 'seenBefore',
                        toReplace: seenBefore,
                    }),
                );
            }
            if (seenBefore) {
                dispatch(
                    addSteps({
                        steps: [
                            {
                                type: 'RecentChangeStep',
                                props: {
                                    problem,
                                },
                            },
                        ],
                        parentId: stepId,
                    }),
                );
            } else {
                dispatch(
                    addStepsForCondition({
                        stepId,
                        problem,
                        isNewProblem: true,
                    }),
                );
            }
        } else {
            if (!reload) {
                await dispatch(
                    updateConsultationSymptom(problem.freeTextProblemId, {
                        field: 'seenBefore',
                        toReplace: seenBefore,
                    }),
                );
            }
            if (seenBefore) {
                // TODO: insert steps for existing free text problem
            } else {
                dispatch(
                    addStepsForFreeTextProblem({
                        stepId,
                        problem,
                        isNewProblem: true,
                    }),
                );
            }
        }
    };
}

export function undoProblemSeenBeforeStep({stepId}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        if (problem.type === PROBLEM_TYPE_SYMPTOM) {
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'seenBefore',
                    toReplace: null,
                }),
            );
        } else if (problem.type === PROBLEM_TYPE_CONDITION) {
            await dispatch(
                updatePastCondition(problem.pastConditionId, {
                    field: 'seenBefore',
                    toReplace: null,
                }),
            );
        } else {
            await dispatch(
                updateConsultationSymptom(problem.freeTextProblemId, {
                    field: 'seenBefore',
                    toReplace: null,
                }),
            );
        }
    };
}

import {defineMessages} from 'react-intl';

import {
    pastConditionDiagnosedTimeFrameAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import {updatePastCondition} from 'consultations/actions/consultationActions.js';
import NumberSelectQuestion from 'questions/components/NumberSelectQuestion.js';

const messages = defineMessages({
    title: {
        id: 'consultations.PastConditionWhenDiagnosedStep.title',
        defaultMessage: 'title',
    },
    placeholder: {
        id: 'consultations.PastConditionWhenDiagnosedStep.placeholder',
        defaultMessage: 'placeholder',
    },
    trailingText: {
        id: 'consultations.PastConditionWhenDiagnosedStep.trailingText',
        defaultMessage: 'trailingText',
    },
});

/*
    Clinical:
    When was the patient first diagnosed with the condition?

    Technical:
    N/A

    Dependencies:
    Shown when the patient selects conditions on the ProblemSearchStep
    and answers 'Yes' on the PastConditionHasBeenDiagnosedStep.

    Future:
    N/A
*/

export default createStep({
    question: NumberSelectQuestion,
    mapState: (store, props) => ({
        placeholder: props.intl.formatMessage(messages.placeholder),
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
        trailingText: props.intl.formatMessage(messages.trailingText),
        options: translateAnswers(
            pastConditionDiagnosedTimeFrameAnswers,
            props.intl,
        ),
    }),
    reloadAction: reloadPastConditionWhenDiagnosedStep,
    submitAction: submitPastConditionWhenDiagnosedStep,
    undoAction: undoPastConditionWhenDiagnosedStep,
});

export function reloadPastConditionWhenDiagnosedStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'PastConditionWhenDiagnosedStep',
            stepId,
        });
        if (previousStep) {
            const {pastConditionId} = previousStep.component.props.problem;
            const {
                whenDiagnosed,
                whenDiagnosedTimeFrame,
            } = store.data.consultations.pastConditions[pastConditionId];
            return {
                answer: {
                    num: String(parseFloat(whenDiagnosed)),
                    option: whenDiagnosedTimeFrame,
                },
            };
        }
    };
}

export function submitPastConditionWhenDiagnosedStep({props, answer}, reload) {
    return async function(dispatch) {
        if (!reload) {
            const {pastConditionId} = props.problem;
            await dispatch(
                updatePastCondition(pastConditionId, {
                    field: 'whenDiagnosed',
                    toReplace: answer.num,
                }),
            );
            await dispatch(
                updatePastCondition(pastConditionId, {
                    field: 'whenDiagnosedTimeFrame',
                    toReplace: answer.option,
                }),
            );
        }
    };
}

export function undoPastConditionWhenDiagnosedStep({props}) {
    return async function(dispatch) {
        const {pastConditionId} = props.problem;
        const toReplace = null;

        await dispatch(
            updatePastCondition(pastConditionId, {
                field: 'whenDiagnosed',
                toReplace,
            }),
        );
        await dispatch(
            updatePastCondition(pastConditionId, {
                field: 'whenDiagnosedTimeFrame',
                toReplace,
            }),
        );
    };
}

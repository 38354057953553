import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    title: {
        id: 'consultations.TimeOfDayStep.title',
        defaultMessage: 'title',
    },
});

const TIME_OF_DAY_MORNING = {
    id: 'TODMORN',
    text: 'Morning',
};
const TIME_OF_DAY_AFTERNOON = {
    id: 'TODAFTERNOON',
    text: 'Afternoon',
};
const TIME_OF_DAY_EVENING = {
    id: 'TODEVE',
    text: 'Evening',
};
const TIME_OF_DAY_NIGHT = {
    id: 'TODNIGHT',
    text: 'Night',
};

export const symptomTimeOfDayAnswers = [
    TIME_OF_DAY_MORNING,
    TIME_OF_DAY_AFTERNOON,
    TIME_OF_DAY_EVENING,
    TIME_OF_DAY_NIGHT,
];

/*
    Clinical:
    Is the symptom worse at a particular time of day?

    Technical:
    N/A

    Dependencies:
    Only shown if the patient selects "constant and continuous" in
    PatternStep

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    props: {
        answers: symptomTimeOfDayAnswers,
    },
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.title),
    }),
    reloadAction: reloadTimeOfDayStep,
    submitAction: submitTimeOfDayStep,
    undoAction: undoTimeOfDayStep,
});

export function reloadTimeOfDayStep(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'TimeOfDayStep',
            stepId,
        });
        if (previousStep) {
            const {problem} = previousStep.component.props;
            const {consultationSymptoms} = store.data.consultations;
            const timeOfDay =
                consultationSymptoms[problem.consultationSymptomId].timeOfDay;
            if (timeOfDay) {
                const selectedAnswers = symptomTimeOfDayAnswers.filter(
                    (answer) => timeOfDay.some((tod) => answer.id === tod),
                );
                return {
                    answers: selectedAnswers,
                };
            }
        }
    };
}

export function submitTimeOfDayStep({stepId, answers}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {problem} = step.component.props;
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'timeOfDay',
                    toAdd: answers.map((answer) => answer.id),
                }),
            );
        }
    };
}

export function undoTimeOfDayStep({stepId, answers}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        if (answers.length) {
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'timeOfDay',
                    toReplace: [],
                }),
            );
        }
    };
}

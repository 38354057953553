import {defineMessages} from 'react-intl';

import {
    WALK_IN_EXISTING_PATIENT,
    WALK_IN_NEW_PATIENT,
    walkInPatientFlowAnswers,
    translateAnswers,
} from 'clinical/helpers.js';
import {updateConsultation} from 'consultations/actions/consultationActions.js';
import {addDefaultSteps, undoStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.WalkInPatientFlowStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    N/A

    Technical:
    N/A

    Dependencies:
    Only shown if the practice's solution is set to walk in or if the
    questionnaire is for a non-integrated workflow.

    Future:
    N/A
*/

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        answers: translateAnswers(walkInPatientFlowAnswers, props.intl),
    }),
    reloadAction: reloadWalkInPatientFlowStep,
    submitAction: submitWalkInPatientFlowStep,
    undoAction: undoWalkInPatientFlowStep,
});

export function reloadWalkInPatientFlowStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'WalkInPatientFlowStep',
        );
        if (previousStep) {
            const {
                isExistingPatient,
            } = getState().data.consultations.consultation;
            const previousAns = isExistingPatient
                ? WALK_IN_EXISTING_PATIENT.id
                : WALK_IN_NEW_PATIENT.id;
            return {
                answer: {
                    id: previousAns,
                },
            };
        }
    };
}

export function submitWalkInPatientFlowStep({answer, stepId}, reload) {
    return async function(dispatch) {
        if (!reload) {
            await dispatch(
                updateConsultation({
                    field: 'isExistingPatient',
                    toReplace: answer.id === WALK_IN_EXISTING_PATIENT.id,
                }),
            );
        }
        dispatch(addDefaultSteps(stepId));
    };
}

export function undoWalkInPatientFlowStep({stepId}) {
    return async function(dispatch) {
        await dispatch(
            updateConsultation({
                field: 'isExistingPatient',
                toReplace: false,
            }),
        );
        dispatch(undoStep(stepId));
    };
}
